"use strict";

const modalData = {
    "loginInputError": {
        "message": {
            "error": "IDとパスワードを入力してください。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "loginError": {
        "message": {
            "error": "ログインに失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "sendEmailPassword": {
        "message": {
            "start": "パスワードをメールで送信しますか？",
            "end": "送信しました！",
            "error": "送信に失敗しました。",
        },
        "button": {
            "primary": "送信する",
            "cancel": "キャンセル",
            "finished": "閉じる",
            "error": "閉じる",
        },
    },
    "teamCreate": {
        "message": {
            "start": "チームを登録しますか？",
            "end": "登録しました！",
            "error": "チームの登録に失敗しました。",
        },
        "button": {
            "primary": "チームを登録する",
            "cancel": "キャンセル",
            "finished": "チーム一覧へ戻る",
            "error": "閉じる",
        },
    },
    "teamUpdate": {
        "message": {
            "start": "チームを編集しますか？",
            "end": "編集しました！",
            "error": "チームの編集に失敗しました。",
        },
        "button": {
            "primary": "チームを編集する",
            "cancel": "キャンセル",
            "finished": "チーム一覧へ戻る",
            "error": "閉じる",
        },
    },
    "teamDelete": {
        "message": {
            "start": "チームを削除しますか？",
            "end": "削除しました！",
            "error": "チームの削除に失敗しました。",
        },
        "button": {
            "delete": "チームを削除する",
            "cancel": "キャンセル",
            "finished": "チーム一覧へ戻る",
            "error": "閉じる",
        },
    },
    "teamFetchError": {
        "message": {
            "error": "チームの取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "changePassWord": {
        "message": {
            "start": "パスワードを変更しますか？",
            "end": "変更しました！",
            "error": "パスワードの変更に失敗しました。",
        },
        "button": {
            "primary": "パスワードを変更する",
            "cancel": "キャンセル",
            "finished": "チーム一覧へ戻る",
            "error": "閉じる",
        },
    },
    "keywordCreate": {
        "message": {
            "start": "類似キーワードを登録しますか？",
            "end": "登録しました！",
            "error": "類似キーワードの登録に失敗しました。",
        },
        "button": {
            "primary": "類似キーワードを登録",
            "cancel": "キャンセル",
            "finished": "類似キーワード一覧へ戻る",
            "error": "閉じる",
        },
    },
    "keywordUpdate": {
        "message": {
            "start": "類似キーワードを編集しますか？",
            "end": "編集しました！",
            "error": "類似キーワードの編集に失敗しました。",
        },
        "button": {
            "primary": "類似キーワードを編集",
            "cancel": "キャンセル",
            "finished": "類似キーワード一覧へ戻る",
            "error": "閉じる",
        },
    },
    "keywordDelete": {
        "message": {
            "start": "類似キーワードを削除しますか？",
            "end": "削除しました！",
            "error": "類似キーワードの削除に失敗しました。",
        },
        "button": {
            "delete": "類似キーワードを削除",
            "cancel": "キャンセル",
            "finished": "類似キーワード一覧へ戻る",
            "error": "閉じる",
        },
    },
    "keywordFetchError": {
        "message": {
            "error": "類似キーワードの取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "supportDetailsSearchError": {
        "message": {
            "error": "支援計画書の検索に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "supportDetailFetchError": {
        "message": {
            "error": "支援計画書の取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "commentCreate": {
        "message": {
            "start": "コメントを送信しますか？",
            "end": "送信しました！",
            "error": "コメントの送信に失敗しました。",
        },
        "button": {
            "primary": "コメントを送信する",
            "cancel": "キャンセル",
            "finished": "閉じる",
            "error": "閉じる",
        },
    },
    "commentCreateError": {
        "message": {
            "error": "管理者はコメントの送信ができません。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "commentDelete": {
        "message": {
            "start": "コメントを削除しますか？",
            "end": "削除しました！",
            "error": "コメントの削除に失敗しました。",
        },
        "button": {
            "delete": "コメントを削除する",
            "cancel": "キャンセル",
            "finished": "閉じる",
            "error": "閉じる",
        },
    },
    "commentFetchError": {
        "message": {
            "error": "コメントの取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "likeUpdateError": {
        "message": {
            "error": "いいねの更新に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "likeFetchError": {
        "message": {
            "error": "いいねの取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
    "releaseUpdate": {
        "message": {
            "start": "公開状態を切り替えますか？",
            "end": "切り替えました！",
            "error": "公開状態の切り替えに失敗しました。",
        },
        "button": {
            "primary": "公開状態を切り替える",
            "cancel": "キャンセル",
            "finished": "閉じる",
            "error": "閉じる",
        },
    },
    "userRoleFetchError": {
        "message": {
            "error": "ユーザ役割の取得に失敗しました。",
        },
        "button": {
            "error": "閉じる",
        },
    },
};

export default modalData;
