"use strict";

import axios from "axios";

export default {
    data() {
        return {
            "CancelToken": axios.CancelToken,
            "sources": {},
        };
    },
    "methods": {
        $_requestConfig(option) {
            const requestConfig = {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`,
                },
            };
            if (option === "file") {
                requestConfig.headers["Content-Type"] = "multipart/form-data";
            }
            if (option === "SelectDate" || option === "params") {
                requestConfig.headers["Content-Type"] = "application/json";
                requestConfig.params = this.params;
            }
            if (option === "SelectDate") {
                requestConfig.cancelToken = this.$_cancelToken(option);
            }
            return requestConfig;
        },
        $_cancelToken(key) {
            let sourceKey = "default";
            if (key) {
                sourceKey = key;
            }
            this.sources[sourceKey] = this.CancelToken.source();
            return this.sources[sourceKey].token;
        },
        $_requestCancel(key) {
            let sourceKey = "default";
            if (key) {
                sourceKey = key;
            }
            if (typeof this.sources[sourceKey] !== "undefined") {
                this.sources[sourceKey].cancel();
            }
        },
    },
};
