"use strict";

import Modal from "@/components/Modal.vue";
import modalData from "@/mixins/modalData";

export default {
    "components": {
        Modal,
    },
    data() {
        return {
            "modal": {
                "data": "",
                "status": {
                    "show": false,
                    "finished": false,
                    "error": false,
                },
            },
            "modalData": modalData,
        };
    },
    "methods": {
        $_modalOpen(key) {
            if (typeof(key) === "string") {
                this.modalData[key].key = key;
                this.modal.data = this.modalData[key];
            }
            this.modal.status.show = true;
            this.modal.status.finished = false;
            this.modal.status.error = false;
        },
        $_modalClose() {
            this.modal.status.show = false;
            this.modal.status.finished = false;
            this.modal.status.error = false;
        },
        $_modalFinished(key) {
            if (typeof(key) === "string") {
                this.modalData[key].key = key;
                this.modal.data = this.modalData[key];
            }
            this.modal.status.show = true;
            this.modal.status.finished = true;
        },
        $_modalError(key) {
            if (typeof(key) === "string") {
                this.modalData[key].key = key;
                this.modal.data = this.modalData[key];
            }
            this.modal.status.show = true;
            this.modal.status.error = true;
        },
    },
};
