"use strict";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import ja from "vee-validate/dist/locale/ja.json";
for (let rule in rules) {
    extend(rule, {
        ...rules[rule],
        "message": ja.messages[rule],
    });
}

export default {
    "components": {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            "classButtonStyle": "button--oval--",
            "flagValidatedImmediate": false,
            "X_validatedButtonStyle": "button--oval--cancel",
        };
    },
    "methods": {
        $_setValidatedDisabled(invalid) {
            // バリデーションによるボタンの有効化・無効化
            if (invalid) {
                this.X_validatedButtonStyle = `${this.classButtonStyle}cancel`;
                return true;
            } else {
                this.X_validatedButtonStyle = `${this.classButtonStyle}primary`;
                return false;
            }
        },
        $_setValidatedImmediate() {
            this.flagValidatedImmediate = true;
        },
        $_setValidatedImmediateRemove() {
            this.flagValidatedImmediate = false;
        },
        $_getValidatedImmediate() {
            return this.flagValidatedImmediate;
        },
    },
};
