<template>
    <div v-if="loader">
        <img class="loader" src="@/assets/images/loading-icon.png" alt="">
    </div>
</template>

<script>
    "use strict";

    export default {
        "props": {
            "loader": {
                "default": false,
                "required": true,
            },
        },
    };
</script>
